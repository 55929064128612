/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "./logo.png";

function App() {
  const [songList, setSongList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [helperText, setHelperText] = useState("");
  const [selectedSong, setSelectedSong] = useState(null);
  const [, setShowFaves] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleFave = () => {
    const faveList = JSON.parse(localStorage.getItem("faveList")) || [];

    const songExists = faveList.find((song) => {
      return song === selectedSong.number;
    });

    if (songExists) {
      selectedSong.isFave = false;
      const newFaveList = faveList.filter((song) => {
        return song !== selectedSong.number;
      });

      localStorage.setItem("faveList", JSON.stringify(newFaveList));
    } else {
      selectedSong.isFave = true;
      faveList.push(selectedSong.number);

      localStorage.setItem("faveList", JSON.stringify(faveList));
    }

    setSelectedSong(null);
  };

  const processSearch = () => {
    if (searchTerm.length < 3) {
      setFilteredList([]);
      return;
    }

    const filteredList = songList.filter((song) => {
      return (
        song.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        song.artist.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    setFilteredList(filteredList);

    if (filteredList.length === 0) {
      setHelperText(
        "No results found, you will have better luck searching with less words, or just a part of the name.  For instance, 'Garth' instead of 'Garth Brooks'"
      );
    } else {
      setHelperText("");
    }
  };

  useEffect(() => {
    processSearch();
  }, [songList]);

  const displayFaves = () => {
    const filteredList = songList.filter((song) => {
      return song.isFave;
    });

    setFilteredList(filteredList);
  };

  useEffect(() => {
    const faveList = JSON.parse(localStorage.getItem("faveList")) || [];

    setLoading(true);
    fetch("SongList.txt")
      .then((response) => response.text())
      .then((data) => {
        const dataRows = data.split("\n");

        const songJson = dataRows.map((row) => {
          const songDetails = row.split("|");

          let artistName = songDetails[2].trim();

          // count the number of commas in the artist name
          const commaCount = artistName.split(",").length - 1;
          if (commaCount === 1) {
            // if there is only one comma, then the artist name is in the format "Last, First"
            const artistParts = artistName.split(",");
            const fName = artistParts[1].trim();
            const lName = artistParts[0].trim();

            if (fName.includes("Jr") && !fName.includes("&")) {
              artistName = fName.replace("Jr", "") + lName + " Jr";
            } else {
              artistName = fName + " " + lName;
            }
          }
          // remove all . from the name
          artistName = artistName.replace(/\./g, "");

          return {
            number: songDetails[0].trim(),
            title: songDetails[1].trim(),
            artist: artistName,
            isFave: faveList.includes(songDetails[0].trim()),
          };
        });

        const filtererList = songJson.filter((song) => {
          return song.number < 900000;
        });

        const sortedList = filtererList.sort((a, b) =>
          a.artist > b.artist ? 1 : b.artist > a.artist ? -1 : 0
        );

        setSongList(sortedList);
        setLoading(false);
      });
  }, []);

  return (
    <div className="App">
      <div>
        <Container>
          <Row>
            <Col xs={3}></Col>
            <Col xs={6}>
              <img alt="Red Pirate Logo" src={logo} height="100" />
            </Col>
            <Col xs={3}>
              <Button
                variant="dark"
                onClick={() => {
                  setShowFaves(true);
                  displayFaves();
                }}
              >
                <span style={{ color: "white", fontSize: "30px" }}>
                  &#9733;
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
        <h3>Saturdays 7:00 PM - 10:00 PM</h3>
        <Container style={{ marginBottom: "20px", marginTop: "20px" }}>
          <Row>
            <Col xs={8}>
              <Form.Control
                onChange={(evt) => setSearchTerm(evt.target.value)}
                type="text"
                id="search"
                autoComplete="off"
                placeholder="Search for a song or artist"
                autoCorrect="off"
                autoCapitalize="off"
              />
            </Col>
            <Col xs={4}>
              <Button
                variant="dark"
                onClick={() => {
                  setShowFaves(false);
                  processSearch();
                }}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Container>

        <table width="100%" border="1" padding="20">
          <thead
            style={{ color: "white", background: "black", fontWeight: "bold" }}
          >
            <th width="20%">Number</th>
            <th width="40%">Title</th>
            <th width="40%">Artist</th>
          </thead>

          {loading && (
            <tr>
              <td colSpan={3}>
                <div style={{ textAlign: "center" }}>Loading Song List...</div>
              </td>
            </tr>
          )}

          {filteredList.map((song) => (
            <tr
              onClick={() => {
                setSelectedSong(song);
              }}
              key={song.number}
              style={{ borderBottom: "1px solid black", minHeight: "80px" }}
            >
              <td style={{ fontWeight: "bold", fontSize: "18px" }}>
                {song.number}
              </td>
              <td>
                {" "}
                {song.isFave && (
                  <span style={{ color: "darkred", marginRight: "4px" }}>
                    &#9733;
                  </span>
                )}
                {song.title}
              </td>
              <td>{song.artist}</td>
            </tr>
          ))}
        </table>
        {helperText === "" ? null : (
          <div style={{ marginBottom: "20px" }}>{helperText}</div>
        )}
        {selectedSong && (
          <div className="popup">
            <div>
              <Button
                style={{
                  width: "100%",
                  marginBottom: "20px",
                  fontWeight: "bold",
                }}
                size="lg"
                variant="dark"
                onClick={() => toggleFave()}
              >
                {selectedSong.isFave ? "Remove " : "Add "}
                Favorite
              </Button>
              <div className="songnumber">{selectedSong.number}</div>
              <div className="songTitle">{selectedSong.title}</div>
              <div>{selectedSong.artist}</div>
              <Button
                style={{ width: "100%", marginTop: "20px", fontWeight: "bold" }}
                size="lg"
                variant="dark"
                onClick={() => setSelectedSong(null)}
              >
                Close
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
